import React from 'react'
import { Button } from '../../components/button'
import HeaderMobile from './mobile'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './style.scss'

const Header = ({ city }: { city: string }) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <header className={'container'}>
        <div className={'container__row'}>
          <div style={{ background: 'white' }}>
            <div className={'header '}>
              <div className={'header__top'}>
                <div className={'header__top__block-location'}>
                  <div
                    className={'header__top__block-location__logo'}
                    style={{ marginRight: 20, fontWeight: '500' }}
                  >
                    <Link to={'/documents'}>Документы</Link>
                  </div>
                </div>
                <div className={'header__top__block-button'}>
                  <Button
                    theme={'dark'}
                    title={'Оформить займ'}
                    style={{ marginLeft: 18 }}
                    onClick={() => navigate('/register')}
                    iconButton={<img src={'/assets/login.svg'} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <HeaderMobile />
    </React.Fragment>
  )
}

export default Header
