import React, { useLayoutEffect } from 'react'
import './style.scss'
import { about, convenience, questionsAnswers } from './data'
import BlockCalculate from '../../../components/block-calculate'
import AboutHome from './components/about'
import { useRequest } from '../../../request/hooks/use-request'
import Article from './components/articles'
import Questions from './components/questions'
import { addDays, format } from 'date-fns'
import { AddedAdsFin } from '../../../helpers/add-adsFin'

const HomePage = (props: any) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(AddedAdsFin(), 1000)
  }, [])

  return (
    <div className={'home '}>
      <BlockCalculate />
      <div className={'container documents'}>
        <div className={'container__row'}>
          <ins
            className="dd15595d"
            data-key="ee3fe6a81ea6f9ed6909427fd1349626"
          ></ins>
        </div>
      </div>
      <AboutHome data={about} />
      {/* <Questions />
      <div className={'container'}>
        <div className={'container__row'}>
          <div className={' articles'}>
            <div className={''}>
              <div className={'articles__title'}>
                <h1 className={'text-title'}>Актуальные статьи</h1>
              </div>
              <div className={'articles__cards'}>
                <Article
                  title={'История развития МФО'}
                  desc={
                    'МФО, которые мы привыкли видеть сейчас, появились в России в середине 90-ых. Тогда было тяжелое время для наших сограждан: госпредприятия...'
                  }
                  link={'/articles/1'}
                  date={format(addDays(new Date(), -1), 'dd.MM.yyyy')}
                />
                <Article
                  title={'Коронавирус'}
                  desc={
                    'Как защитить себя и свой бюджет? В этой статье мы расскажем, как защитить себя от коронавируса. Простые советы помогут вам и вашим близким избежать заражения...'
                  }
                  link={'/articles/2'}
                  date={format(addDays(new Date(), -2), 'dd.MM.yyyy')}
                />
                <Article
                  title={'Банкротство'}
                  desc={
                    'Всем нам нужны деньги, иногда они требуются очень срочно, поэтому мы прибегаем к помощи кредитов. Часто человек даже не замечает, как оброс долгами. Он не справляется с выплатой займо...'
                  }
                  date={format(addDays(new Date(), -4), 'dd.MM.yyyy')}
                  link={'/articles/3'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutHome data={convenience} title={'Почему с нами удобно работать?'} /> */}
    </div>
  )
}

export default HomePage
