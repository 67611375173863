import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { authSchema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField } from '@mui/material'
import { TextMaskCustom } from '../../../components/mask-phone'
import './style.scss'
import { Button } from '../../../components/button'
import { Link, useNavigate } from 'react-router-dom'
import { requestAuth, requestTraffics } from '../../../request/hooks/api'
import { UserContext } from '../../../context/auth'

const Login = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(authSchema),
    defaultValues: {
      phone: '',
      password: '',
    },
  })

  React.useEffect(() => {
    // requestTraffics(`/login`, null)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  React.useEffect(() => {
    if (error !== null) {
      setErrors(null)
    }
  }, [watch('password'), watch('phone')])

  const navigate = useNavigate()
  const { setAuth }: any = useContext(UserContext)
  const [error, setErrors] = React.useState(null)

  const onSubmit = (data: any) => {
    requestAuth({ ...data })
      .then(response => response.data)
      .then(data => {
        localStorage.setItem('token', String(data.token.token))
        localStorage.setItem('refreshToken', String(data.token.refreshToken))
        localStorage.setItem('userInfo', JSON.stringify(data.user))
        setAuth(true)
        return navigate('/profile')
      })
      .catch(error => setErrors(error.response.data.error.message))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={'register-container'}
        style={{
          backgroundImage: `url('/assets/background/moneytka.svg')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={'register-container__block'}>
          <div className={'register-container__block__title'}>
            Вход в личный кабинет
          </div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <div className={'personal-data__block-input custom-block'}>
                <label className={'personal-data__label'}>Ваш телефон</label>
                <TextField
                  {...field}
                  id="formatted-numberformat-input"
                  placeholder={'7(999)-999-99-99'}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  error={!!errors[field.name]?.message}
                  helperText={errors[field.name]?.message}
                />
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div className={'personal-data__block-input custom-block'}>
                <label className={'personal-data__label'}>Пароль</label>
                <TextField
                  {...field}
                  placeholder={'******'}
                  type={'password'}
                  error={!!errors[field.name]?.message}
                  helperText={errors[field.name]?.message}
                />
              </div>
            )}
          />
          <div className={'text-error'}>{error}</div>
          <div className={'register-container__button-reload-password'}>
            <Link to={'/reload-password'}>Забыли пароль?</Link>
          </div>
          <Button
            title={'Войти'}
            onClick={handleSubmit(onSubmit) as any}
            theme={'dark'}
            className={'register-container__button-login'}
            iconButton={<img src={'/assets/login2.svg'} />}
          />
          <Button
            title={'Зарегистрироваться'}
            onClick={() => navigate('/register')}
            theme={'white'}
            className={'register-container__button-register'}
            iconButton={<img src={'/assets/added.svg'} />}
          />
        </div>
      </div>
    </form>
  )
}

export default Login
