import { CircularProgress, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaStepPersonalData } from './schema'
import { useRequestDaData } from '../../../request/hooks/use-request-dadata'
import { Button } from '../../../components/button'
import SelectDay from '../../../components/SelectDay'
import { TextMaskCustom } from '../../../components/mask-phone'

const StepPersonalDate = (props: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaStepPersonalData),
    defaultValues: {
      firstName: '',
      phone: '',
    },
  })
  const [loading, setLoading] = React.useState(false)

  const onSubmit = (data: any) => {
    setLoading(true)
    //@ts-ignore
    window.location.href = localStorage.getItem('offer_link')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'personal-data'}>
      <div className={'personal-data__block-title'}>
        <div className={'personal-data__block-title__title'}>
          Персональные данные
        </div>
        <div className={'personal-data__block-title__desc'}>
          Заполните данные и получите +25% к одобрению
        </div>
      </div>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Ваша фамилия</label>
            <TextField
              {...field}
              id="formatted-numberformat-input"
              placeholder={'Иванов'}
              error={!!errors[field.name]?.message}
              helperText={errors[field.name]?.message}
            />
          </div>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Ваш телефон</label>
            <TextField
              {...field}
              id="formatted-numberformat-input"
              placeholder={'7(999)-999-99-99'}
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
              error={!!errors[field.name]?.message}
              helperText={errors[field.name]?.message}
            />
          </div>
        )}
      />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color={'success'} />
        </div>
      ) : (
        <div className={'personal-data__button-block'} style={{ marginTop: 0 }}>
          <Button
            title={'Регистрация'}
            onClick={handleSubmit(onSubmit) as any}
            theme={'dark'}
          />
        </div>
      )}
    </form>
  )
}

export default StepPersonalDate
