import React from 'react'
import { Button } from '../../../components/button'
import { requestPayment } from '../../../request/hooks/api'

const StepLast = (props: { state: any }) => {
  return (
    <div className={'personal-data'}>
      <div className={'personal-data__block-title'}>
        <div className={'personal-data__block-title__title'}>
          Регистрация прошла успешно!
        </div>
        <div className={'personal-data__block-title__desc'}>
          Теперь Вы вместе с нами!
        </div>
      </div>
      <div>
        <Button
          title={'Личный кабинет'}
          onClick={() => requestPayment()}
          theme={'dark'}
        />
      </div>
    </div>
  )
}

export default StepLast
