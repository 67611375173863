import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/button'
import { UserContext } from '../../context/auth'

const HeaderMobile = () => {
  const [checked, setChecked] = React.useState(false)

  return (
    <header className="container-mobile">
      <input
        checked={checked}
        onChange={() => setChecked(pre => !pre)}
        id="toggle"
        type="checkbox"
      />
      <label className="toggle-container" htmlFor="toggle">
        <span className="button button-toggle"></span>
      </label>
      <nav className="nav">
        <Link
          onClick={() => setChecked(pre => !pre)}
          className="nav-item"
          to="/documents"
        >
          <div> Документы</div>
        </Link>
      </nav>
    </header>
  )
}

export default HeaderMobile
