import React, { useState } from 'react'
import './style.scss'
import { SliderCustom } from './slider'
import { useConvertDate } from '../helpers/use-convert-date'
import { useNavigate } from 'react-router'
import { addDays, format } from 'date-fns'
import { ru } from 'date-fns/locale'

const marksMonth = [
  {
    value: 1,
  },
  {
    value: 365,
  },
]

const BlockCalculate = () => {
  const [loanTerm, setLoanTerm] = useState(29) //Срок займа
  const [sum, setSum] = useState(50000) //Сумма займа
  const navigate = useNavigate()
  function register() {
    //@ts-ignore
    return navigate('/register')
    // return (window.location.href = localStorage.getItem('offer_link'))
    // localStorage.setItem('sum', String(sum))
    // localStorage.setItem('term', String(loanTerm))
    //  navigate('/register')
    // if (String(localStorage.getItem('showcase_google')) === 'true') {
    //   return navigate(
    //     `/google-page?search=${JSON.stringify('займы без отказа')}`
    //   )
    // }
    // return navigate('/showcase', { state: { type: 10 } })
  }
  const result = addDays(new Date(), loanTerm)
  return (
    <div
      className={'custom-block-header'}
      style={{
        background: 'linear-gradient(90deg, #1b3666,#1f6394)',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={{ zIndex: 10 }}>
        <div className={'calculate '}>
          <div className={'calculate__block-text'}>
            <h1>
              Первый
              <br /> займ <span> Бесплатно</span>
            </h1>
            <h3>До 30 000 рублей, до 21 дня — без процентов!</h3>
          </div>

          <div className={'calculate__block-calc'}>
            <div
              className={'calculate__block-calc__loan'}
              style={{ marginBottom: 32 }}
            >
              <div className={'calculate__block-calc__loan__title'}>
                <div>Выберите сумму</div>
                <div>
                  {new Intl.NumberFormat('ru-RU').format(sum)}
                  <span style={{ paddingLeft: 10 }}>₽</span>
                </div>
              </div>
              <div className={'calculate__block-calc__loan__slider'}>
                <SliderCustom
                  defaultValue={sum}
                  max={100000}
                  min={1000}
                  step={1000}
                  setValue={setSum}
                  marks={[{ value: 1000 }, { value: 100000 }]}
                />
              </div>
              <div className={'calculate__block-calc__loan__sum'}>
                <div>1 000 ₽</div>
                <div>100 000 ₽</div>
              </div>
            </div>

            <div className={'calculate__block-calc__loan'}>
              <div className={'calculate__block-calc__loan__title'}>
                <div>Выберите срок</div>
                <div>
                  {useConvertDate(loanTerm).value}{' '}
                  <span>{useConvertDate(loanTerm).textDate}</span>
                </div>
              </div>
              <div className={'calculate__block-calc__loan__slider'}>
                <SliderCustom
                  defaultValue={loanTerm}
                  max={365}
                  min={5}
                  step={1}
                  setValue={setLoanTerm}
                  marks={marksMonth}
                />
              </div>
              <div className={'calculate__block-calc__loan__sum'}>
                <div>7 дней</div>
                <div>12 месяцев</div>
              </div>
            </div>
            <div className={'calculate__block-calc__sum'}>
              <div className={'calculate__block-calc__sum__date'}>
                <span>Срок до</span>
                <p>
                  ~{' '}
                  {format(addDays(new Date(), loanTerm), 'dd.MM.yyyy', {
                    locale: ru,
                  })}
                </p>
              </div>
              <div className={'calculate__block-calc__sum__sum'}>
                <span>Сумма возврата</span>
                <p>
                  ~{' '}
                  {new Intl.NumberFormat('ru-RU').format(
                    sum + loanTerm * (sum * 0.005)
                  )}
                  <span style={{ paddingLeft: 10 }}>₽</span>
                </p>
              </div>
            </div>
            <div className={'calculate__block-calc__button'}>
              <button onClick={() => register()}>
                Получить {new Intl.NumberFormat('ru-RU').format(sum)}
                <span style={{ paddingLeft: 10 }}>₽</span>
              </button>
            </div>
            <div className={'calculate__block-calc__text'}>
              Все данные зашифрованы по стандарту DES. Для повышения удобства
              работы с сайтом использует файлы cookie. В cookie содержатся
              данные о прошлых посещениях сайта. Если вы не хотите, чтобы эти
              данные обрабатывались, отключите cookie в настройках браузера.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockCalculate
