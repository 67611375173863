import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, TextField } from '@mui/material'
import './style.scss'
import { Button } from '../../../components/button'
import { confirmedCode, confirmedPhone } from '../../../request/hooks/api'
import { schemaVerificationCode } from './schema'
import { TextMaskCustom } from '../../../components/mask-phone'
import { MaskVerificationCode } from '../../../components/mask-verification-code'
import { ModalErrors } from '../../../components/modal-error'

const TIMER = 125

const VerificationCode = (props: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schemaVerificationCode),
    defaultValues: {
      phone: '',
      code: '',
    },
  })

  const [isRequest, setIsRequest] = React.useState(false)
  const [reloadCode, setReloadCode] = React.useState(false)
  const [timer, setTimer] = React.useState(TIMER)
  const [isErrors, setErrors] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (reloadCode) {
      if (timer === 0) {
        setReloadCode(false)
        setTimer(TIMER)
      }
      setTimeout(() => setTimer(prev => prev - 1), 1000)
    }
  }, [timer, reloadCode])

  const onSubmit = (data: any) => {
    setLoading(true)
    confirmedPhone(data.phone)
      .then(() => {
        setIsRequest(true)
        setReloadCode(true)
      })
      .catch(() => setErrors(true))
      .finally(() => setLoading(false))
  }

  const onSubmitCode = (values: any) => {
    confirmedCode({ code: values.code, phone: values.phone }).then(data => {
      if (data?.data?.verified) {
        props.setState((pre: any) => ({ ...pre, phone: values.phone }))
        props.setActiveStep((pre: number) => pre + 1)
      } else {
        setError('code', { message: 'Код неверный' })
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalErrors open={isErrors} handleClose={() => setErrors(false)} />
      <div
        className={'register-container reload-password'}
        style={{
          backgroundImage: `url('/assets/background/moneytka.svg')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={'register-container__block'}>
          <div className={'register-container__block__title'}>
            Подтверждение телефона
          </div>
          <div className={'register-container__block__text'}>
            {isRequest
              ? `На ваш номер ${getValues('phone')} отправлено смс с кодом.`
              : `Укажите Ваш актуальный номер телефона, Вам прийдет смс с кодом для
            подтверждения номера.`}
          </div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <div
                style={{ display: isRequest ? 'none' : 'flex' }}
                className={'personal-data__block-input custom-block'}
              >
                <label className={'personal-data__label'}>Ваш телефон *</label>
                <TextField
                  {...field}
                  id="formatted-numberformat-input"
                  placeholder={'7(999)-999-99-99'}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  error={!!errors[field.name]?.message}
                  helperText={errors[field.name]?.message}
                />
              </div>
            )}
          />
          <Controller
            name="code"
            control={control}
            render={({ field }) => {
              return (
                <div
                  style={{ display: isRequest ? 'flex' : 'none' }}
                  className={'personal-data__block-input custom-block'}
                >
                  <label className={'personal-data__label'}>
                    Код подтверждения
                  </label>
                  <TextField
                    {...field}
                    placeholder={'0-0-0-0'}
                    InputProps={{
                      inputComponent: MaskVerificationCode,
                    }}
                    error={!!errors[field.name]?.message}
                    helperText={errors[field.name]?.message}
                  />
                </div>
              )
            }}
          />
          {isRequest && (
            <div
              style={{
                textAlign: 'left',
                opacity: 0.8,
                cursor: timer === TIMER ? 'pointer' : 'default',
                fontSize: 14,
                color: timer === TIMER ? 'var(--color-primary)' : '#020202',
              }}
              onClick={() => {
                if (timer === TIMER) {
                  setIsRequest(false)
                }
              }}
              className={'register-container__button-reload-password'}
            >
              Изменить номер {timer !== TIMER && `через ${timer} сек.`}
            </div>
          )}
          {isRequest && (
            <Button
              title={'Подтвердить'}
              onClick={handleSubmit(onSubmitCode) as any}
              theme={'dark'}
              className={'register-container__button-login'}
              iconButton={<img src={'/assets/reload.svg'} />}
            />
          )}
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color={'success'} />
            </div>
          ) : (
            <Button
              title={
                isRequest && reloadCode
                  ? `Получить повторно через ${timer} сек.`
                  : 'Получить код'
              }
              disabled={isRequest && reloadCode ? true : false}
              onClick={handleSubmit(onSubmit) as any}
              theme={isRequest ? 'white' : 'dark'}
              className={'register-container__button-login'}
              iconButton={<img src={'/assets/reload.svg'} />}
            />
          )}
        </div>
      </div>
    </form>
  )
}
export default VerificationCode
