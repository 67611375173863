import React from 'react'
import { Document } from './document'
import { documents } from '../../../static-data/documents'
import './style.scss'
import { AddedAdsFin } from '../../../helpers/add-adsFin'
import { NAME_SITE,ACTIVE_SITE } from '../../../env'

const DocumentsPage = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setTimeout(AddedAdsFin(), 1000)
  }, [])
  return (
    <div className={'container documents'}>
      <div className={'container__row'}>
        <div className={'documents__block-title'}>Документы {NAME_SITE}</div>

        <div id="doc-1" style={{ marginTop: 20 }}>
          <ins
            className="dd15595d"
            data-key="95a9485fa07f1514d78d57c4c5d13ab5"
          ></ins>
        </div>
        <div className={'documents__block-doc'} style={{ marginBottom: 20 }}>
        <Document  title={documents[ACTIVE_SITE].title} link={documents[ACTIVE_SITE].link} />
        </div>
        <div id={'doc-2'}>
          <ins
            className="dd15595d"
            data-key="95a9485fa07f1514d78d57c4c5d13ab5"
          ></ins>
        </div>
      </div>
    </div>
  )
}

export default DocumentsPage
