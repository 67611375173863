//@ts-ignore
import dobcash from './files/dobcash.pdf'
//@ts-ignore
import mfuru from './files/mfuru.pdf'
//@ts-ignore
import smsfin from './files/smsfin.pdf'
//@ts-ignore
import vashzaem from './files/vashzaem.pdf'
//@ts-ignore
import moneytka from './files/moneytka.pdf'


export const documents = [

  {
    id: 0,
    title: 'Согласие на обработку персональных данных',
    link: dobcash,
  },
  {
    id: 1,
    title: 'Согласие на обработку персональных данных',
    link: moneytka,
  },
  {
    id: 2,
    title: 'Согласие на обработку персональных данных',
    link: mfuru,
  },
  {
    id: 3,
    title: 'Согласие на обработку персональных данных',
    link: smsfin,
  },
  {
    id: 4,
    title: 'Согласие на обработку персональных данных',
    link: vashzaem,
  },
]
